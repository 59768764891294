<template>
  <b-sidebar
    id="add-new-transportation-line-sidebar"
    :visible="isAddNewTransportationLineSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="500"
    @change="(val) => $emit('update:is-add-new-transportation-line-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- @hidden="resetForm" -->
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('AppTransportationLines.AddNew.addTransportLine') }}
          <!-- {{ $t('DataAccess.ConnectionsStrings.AddNew.newConnectionChain') }} -->
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!--Customs type field--->
          <validation-provider
            #default="validationContext"
            name="Customs"
            rules="required"
          >
            <b-form-group
              :label="$t('AppTransportationLines.columns.typeCustom')"
            >
              <v-select
                v-model="TransportationLineData.aduanaTipoId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="CustomType"
                :reduce="option => option.id"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--name field--->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="$t('AppTransportationLines.columns.name')"
            >
              <b-form-input
                v-model="TransportationLineData.nombre"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--alias field--->
          <validation-provider
            #default="validationContext"
            name="Alias"
            rules="required"
          >
            <b-form-group
              :label="$t('AppTransportationLines.columns.nickname')"
            >
              <b-form-input
                v-model="TransportationLineData.alias"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Description field--->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              :label="$t('AppTransportationLines.columns.description')"
            >
              <b-form-input
                v-model="TransportationLineData.descripcion"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import generalDataService from '@/services/generalData.service'

export default {
  components: {
    BSidebar,
    ValidationObserver,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    vSelect,
  },
  props: {
    isAddNewTransportationLineSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Services */
    const {
      createTransportationLines,
      fetchCustomTypesOptions,
    } = generalDataService()
    /* Data */
    const blankTransportationLineData = {
      aduanaTipoId: null,
      nombre: '',
      descripcion: '',
      alias: '',
    }
    const TransportationLineData = ref(JSON.parse(JSON.stringify(blankTransportationLineData)))
    const resetClassificationData = () => {
      TransportationLineData.value = JSON.parse(JSON.stringify(blankTransportationLineData))
    }
    const CustomType = ref([])
    fetchCustomTypesOptions(data => {
      CustomType.value = data
    })
    /* Events */
    const onSubmit = () => {
      createTransportationLines(TransportationLineData.value)
        .then(() => {
          emit('update:is-add-new-transportation-line-sidebar-active', false)
          emit('refresh-data')
        })
    }

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClassificationData)

    return {
      // Data
      TransportationLineData,
      CustomType,
      // Events
      onSubmit,
      // Validations
      required,
      getValidationState,
      resetForm,
      refFormObserver,
    }
  },
}
</script>

<style>

</style>
